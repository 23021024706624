.carousel {
  position: relative;
}

.carouselImages {
  display: grid;
  gap: var(--spacing-05);
  grid-auto-flow: column;
  margin-top: var(--spacing-07);
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-padding: var(--spacing-05);
  scroll-snap-type: both mandatory;
  scrollbar-width: none;
}

.carouselImages,
.carouselButtons {
  padding: 0 var(--spacing-05);
}

.carouselImages::-webkit-scrollbar {
  display: none;
}

.carouselImages > * {
  aspect-ratio: var(--carousel-section-item-aspect-ratio);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: var(--carousel-section-item-width, 60vw);
}

.carouselButtons {
  display: none;
  justify-content: space-between;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.carouselButton {
  background: var(--color-gray-000);
  border: 1px solid var(--color-gray-200);
  border-radius: 50%;
  box-shadow: 3px 4px 4px rgb(0 0 0 / 5%);
  height: 56px;
  padding: 18px;
  pointer-events: auto;
  width: 56px;
}

.carouselButton:first-child {
  transform: translateX(50%);
}

.carouselButton:last-child {
  transform: translateX(-50%);
}

.carouselButton:disabled {
  visibility: hidden;
}

.carouselButton svg {
  fill: none;
  stroke: var(--color-gray-900);
}

@media (--media-m) {
  .carouselImages,
  .carouselButtons {
    padding-left: calc((100vw - var(--grid-width)) / 2 + var(--spacing-05));
    padding-right: calc((100vw - var(--grid-width)) / 2 + var(--spacing-05));
  }

  .carouselImages > * {
    max-width: var(--carousel-section-item-max-width, 290px);
    scroll-margin-left: calc((100vw - var(--grid-width)) / 2);
    width: var(--carousel-section-item-width, 40vw);
  }
}

@media (--media-l) {
  .carouselButtons {
    display: flex;
  }

  .carouselButton:first-child {
    transform: translateX(-50%);
  }

  .carouselButton:last-child {
    transform: translateX(50%);
  }
}
