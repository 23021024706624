.experiences {
  display: grid;
  gap: var(--spacing-07);
  margin-top: var(--spacing-07);
  text-wrap: initial;
}

.experienceTitle {
  font: var(--typography-headline-06);
  margin-top: var(--spacing-04);
}

@media (--media-l) {
  .experiences {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
