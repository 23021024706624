.description {
  display: flex;
  gap: var(--spacing-02);
}

.description svg {
  fill: none;
  height: 24px;
  stroke: var(--color-main-700);
  stroke-width: 1.5px;
  width: 24px;
}

.card {
  border-radius: var(--border-radius-s);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02);
  padding: var(--spacing-05);
}

.card:nth-child(4n + 1) {
  background: var(--color-main-050);
}

.card:nth-child(4n + 2) {
  background: var(--color-main-100);
}

.card:nth-child(4n + 3) {
  background: var(--color-complementary-100);
}

.card:nth-child(4n + 4) {
  background: var(--color-complementary-200);
  color: var(--color-gray-000);
}

.cardRating {
  display: flex;
  gap: var(--spacing-01);
}

.cardRatingStar {
  fill: none;
  height: 24px;
  stroke: currentcolor;
  stroke-width: 1.5px;
  width: 24px;
}

.cardRatingStarOn {
  fill: currentcolor;
}

.cardReviewer {
  font: var(--typography-label-m);
}

.cardTitle {
  flex-grow: 1;
}

.cardReviewPlatform {
  align-items: center;
  display: flex;
  font: var(--typography-paragraph-xs);
  gap: var(--spacing-02);
}

.cardReviewPlatformImage {
  border-radius: 50%;
  height: 24px;
  width: 24px;
}

@media (--media-m) {
  .description {
    justify-content: center;
  }
}
