.amenity {
  align-items: flex-start;
  display: inline-flex;
  font: var(--typography-label-s);
}

.amenity svg {
  fill: none;
  flex-shrink: 0;
  height: 24px;
  margin-right: var(--spacing-02);
  stroke: var(--theme-accent-color);
  stroke-width: 1.5px;
  transform: translateY(-2px);
  width: 24px;
}

.amenityDescription {
  font: var(--typography-paragraph-s);
}

.amenityMain {
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-03);
  text-align: center;
}
