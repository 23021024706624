.amenitiesMain {
  margin-top: var(--spacing-07);
  padding: var(--spacing-07);
}

.amenitiesMainTitle {
  font: var(--typography-headline-05);
  text-align: center;
}

.amenitiesMainList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-05);
  justify-content: center;
  margin-top: var(--spacing-07);
}

.amenitiesMainList > * {
  flex-basis: 80px;
}

.amenitiesSecondary {
  display: grid;
  gap: var(--spacing-05) var(--spacing-05);
  margin-top: var(--spacing-07);
}

.amenitiesFootnotes {
  font: var(--typography-paragraph-xs);
  margin-top: var(--spacing-05);
}

@media screen and (min-width: 600px) {
  .amenitiesSecondary {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--media-m) {
  .amenitiesSecondary {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--media-l) {
  .amenitiesMainList {
    justify-content: space-between;
  }

  .amenitiesSecondary {
    grid-template-columns: repeat(4, 1fr);
  }
}
