.segments {
  display: grid;
  gap: var(--spacing-05);
  margin-top: var(--spacing-05);
  min-height: 200px;
}

.segments > * {
  height: 200px;
  width: 100%;
}

@media (--media-m) {
  .segments {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--media-l) {
  .segments {
    grid-template-columns: repeat(4, 1fr);
    margin-top: var(--spacing-07);
  }

  .segments > * {
    height: 250px;
  }
}
