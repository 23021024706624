.wrap {
  row-gap: var(--spacing-08);
}

.images {
  order: -1;
}

@media (--media-m) {
  .wrap {
    align-items: center;
  }

  .wrap > * {
    grid-column: span 6;
  }

  .wrap:not(.inverted) .images {
    order: 0;
  }
}

@media (--media-2xl) {
  .images {
    justify-self: center;
  }
}
