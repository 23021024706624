.destinations {
  --carousel-section-item-width: 300px;
  --carousel-section-item-aspect-ratio: 3/4;

  margin-top: var(--spacing-07);
  row-gap: var(--spacing-07);
}

@media (--media-m) {
  .destinations {
    --carousel-section-item-width: 25vw;
    --carousel-section-item-max-width: 450px;
    --carousel-section-item-aspect-ratio: 1/1;
  }
}
