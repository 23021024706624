.card {
  border-radius: var(--border-radius-s);
  color: var(--color-gray-000);
  height: 100%;
  overflow: hidden;
  position: relative;
}

.card::after {
  background: linear-gradient(45deg, rgb(0 0 0 / 35%), transparent 35%);
  content: "";
  inset: 0;
  position: absolute;
}

.cardContent {
  bottom: 0;
  left: 0;
  padding: var(--spacing-05);
  position: absolute;
  right: 0;
  z-index: 1;
}

.cardImage,
.cardImage img {
  height: 100%;
  width: 100%;
}

.cardImage img {
  object-fit: cover;
}

.cardTitle {
  font: var(--typography-headline-05);
}

.cardLabel {
  font: var(--typography-label-m);
}

@media (--media-l) {
  .cardTitle {
    font: var(--typography-headline-04);
  }
}
