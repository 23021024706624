.lastPosts {
  margin-top: var(--spacing-07);
  row-gap: var(--spacing-07);
}

.lastPostsShowAll {
  margin-top: var(--spacing-07);
  text-align: right;
}

@media (--media-m) {
  .post {
    grid-column: span 6;
  }
}

@media (--media-l) {
  .post {
    grid-column: span 4;
  }
}
