.overlappedImages {
  aspect-ratio: 1 / 1;
  position: relative;
  width: 100%;
}

.overlappedImage {
  aspect-ratio: 3 / 2;
  object-fit: cover;
  position: absolute;
  width: 80%;
  z-index: 1;
}

@supports not (aspect-ratio: 3 / 2) {
  .overlappedImages {
    height: 500px;
  }

  .overlappedImage {
    height: 350px;
  }
}

.overlappedImageBelow {
  bottom: 0;
  right: 0;
  z-index: 0;
}

.overlappedImagesInverted .overlappedImageAbove {
  left: auto;
  right: 0;
}

.overlappedImagesInverted .overlappedImageBelow {
  left: 0;
  right: auto;
}

.overlappedImage img {
  border-radius: var(--border-radius-s);
  height: 100%;
  object-fit: cover;
  width: 100%;
}
